@import "../../../assets/scss/variables.scss";

.DoubleTabs {
    display: flex;
    align-items: center;
    background-color: $gray-6;
    border-radius: $radius-main;
    margin: 0 0 20px 0;
}

.DoubleTab {
    text-align: center;
    padding: 4px 20px;
    color: $gray-5;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    cursor: pointer;

    &:hover {
        opacity: 0.85;
        color: $black-main;
    }

    &:active {
        opacity: 0.6;
    }

    &.Active {
        color: white;
        background-color: $purple-dark;
        animation: progress-animation 0.2s ease-in-out 1;
        border-radius: $radius-main;

        &:hover {
            opacity: 1;
        }

        &:active {
            opacity: 1;
        }
    }
}

.Tabs {
    display: flex;
    align-items: center;
}

.Tab {
    text-align: center;
    margin-right: 20px;
    width: 140px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid #0C5DFF;
    color: $bright-blue;
    font-size: 14px;
    background: #ffffff;
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }

    &:active {
        opacity: 0.6;
    }

    &.Active {
        color: white;
        background-color: $bright-blue;
        cursor: auto;

        &:hover {
            opacity: 1;
        }

        &:active {
            opacity: 1;
        }
    }
}

.ToolbarTabs {
    margin: 0;
    margin-right: -20px;
}