@import "../../../assets/scss/variables.scss";

.Wrap {
    display: flex;
    flex-direction: column;
    background-color: $white-main;
    border-radius: $radius-main;
    padding: 30px 32px;
}

.Top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 6px 0;

    &Title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: $black-main;
    }
}

.Link {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: $purple-dark;
    justify-content: space-between;
    align-items: baseline;
}

.List {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 476px;
    overflow: hidden;
}

.Item {
    display: flex;
    margin: 0 0 11px 0;

    &Box {
        padding: 12px 0 0 0;
    }

    &Label {
        font-size: 16px;
        font-weight: 600;
        color: $black-main;
        margin: 0;
    }

    &Date {
        font-size: 14px;
        font-weight: 400;
        color: $gray-3;
        margin: 0;
    }

    &Price {
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        color: $emerald;
        margin: 0 0 0 auto;
        align-content: center;
    }
}

.HistoryItem {
    display: flex;
    align-items: center;
    height: 34px;
    font-size: 0.7em;
    margin-bottom: 9px;
}

.HistoryItemAmount {
    margin-right: 21px;

    .HistoryItem & {
        background-color: #d0dbe5;
        color: black;
        flex-shrink: 0;
    }
}

.SmallTitle {
    line-height: 12px;
}

.HistoryItemImg {
    width: 30px;
    margin-right: 5px;
    border-radius: 10px;
}

.HistoryItemDate {
    margin-left: auto;
}

.ml2 {
    margin-left: 20px;
    height: 100%;
}

.OperationOffice {
    width: 100%;
    margin-bottom: 17px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 33px;
    padding-top: 4px;
}

.ItemTittle {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */
    color: #53575D;
}

.ItemAmount {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #898D92;
}

.ItemData {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #898D92;
    margin-top: 4px;
}

.TittleOperation {
    margin-left: 13px;
    display: flex;
    margin-top: -1px;
    flex-direction: column;
    justify-content: flex-start;
}

.LeftTittleBlock {
    display: flex;
    align-items: center;
}

.FullBlock {
    background: #f0f3f6;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        color: #B3B8BD;
    }
}

.h100 {
    height: 100% !important;
}