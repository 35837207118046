// $tablet: 550px;
// $desktop: 1240px;
// $big-desktop: 1919px;
$tablet: 0px;
$desktop: 0px;
$big-desktop: 0px;
$full-desktop: 1650px;

$padding-tablet: calc(50% - 275px);
$padding-desktop: calc(50% - 607px);
$padding-desktop-big: calc(50% - 744px);

$gap: 30px;
$menu-width-tablet: 0;
$menu-width-desktop: 270px+$gap;
$menu-width-big-desktop: 390px+$gap;

$full-height: calc(100vh - 146px);

//colors
$cold-blue: #0c1750;
$light-blue: #ebf2f7;
$dark-grey: #52575d;
$light-grey: #b3b8bd;
$bright-blue: #0C5DFF;
$bright-green: #1bc167;
// $bright-green: #1da75c;
$bright-yellow: #f2dd58;
$red: #ed4c4c;

///new colors
$gray-1: #60646A;
$gray-2: #BDBDBD;
$gray-3: #AEB0B8;
$gray-5: #898989;
$gray-6: #F4F4F4;

$black-main: #212121;
$white-main: #FFFFFF;

$purple-dark: #691CFF;
$purple: #A948FC;
$yellow: #F4F378;
$coral: #DC603B;
$brown: #782811;
$emerald: #77D180;
$mustard: #F8D069;
///////////////////

///block radius
$radius-main: 20px;
$radius-sub: 15px;
$radius-corner: 0 20px 20px 20px;
///

$transition: 0.2s ease-in;

:export {
  coldBlue: $cold-blue;
}