@import "../../../assets/scss/variables.scss";

$transition: 0.1s ease-out;

.Wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 6px;
    height: 34px;
    background-color: #ebf2f7;
    font-weight: 700;
    border-radius: 20px;
    overflow: hidden;
    font-size: 1.2em;
}

.Plan {
    display: none;
    height: 40px;
    margin: auto 0;

    .Percent {
        font-size: 18px;
    }
}

.MobileWrapper {
    margin-bottom: 15px;
}

.Price {
    // font-size: 12px;
    color: #ed4c4c;
    transition: color $transition;
    margin-left: 8px;
    margin-top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;


}

.Percent {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    // font-size: 16px;
    color: #3d3d3d;
    margin-right: 8px;
    margin-top: 0;

}

.Line {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 20px;
    opacity: 0;
    transition: opacity $transition;
}

.LineRed {
    opacity: 1;
    background-color: rgb(255, 217, 217);
    //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 217, 217, 1) 100%);
}

.LineYellow {
    background-color: rgb(254, 255, 217);
    //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(254, 255, 217, 1) 100%);
}

.LineGreen {
    background-color: rgb(237, 254, 224);
    //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(237, 254, 224, 1) 100%);
}

.LineVisibleYellow,
.LineVisibleGreen {
    opacity: 1;
}

.LineVisibleYellow~.Price {
    color: #f4db50;
}

.LineVisibleGreen~.Price {
    color: #1bc167;
}

@media (max-width: $desktop) {
    .Plan {
        display: flex;
    }
}