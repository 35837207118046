@import "../../../assets/scss/variables.scss";

$modal-width: 440px;
.ModalWindow {
  height: fit-content;
  max-height: calc(100vh + 40px);
  width: fit-content;
  margin-top: -40px;
  top: 40px;
  background: #fff;
  border: none;
  -webkit-overflow-scrolling: touch;
  border-radius: $radius-main;
  outline: none;
}

@media (max-width: 900px ) {
  .ModalWindow {
    margin-top: 100px;
  }
}

.ModalOverlay {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.6);
  //backdrop-filter: blur(2px);
  display: grid;
  justify-content: center;
  align-items: center;

}
.paddingLeft {
  padding-left: 258px;
}
@media (max-width: 1650px ) {
  .paddingLeft {
    padding-left: 0;
  }
}
