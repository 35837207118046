@import "../../assets/scss/variables.scss";

.Wrapper {
    position: relative;
    z-index: 0;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    min-height: 100%;
    background-size: cover;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0c1750;
        opacity: 0.7;
    }
}

.Logo {
    display: block;
    margin-top: 32px;
    width: 92px;
}

.Form {
    width: 516px;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    font-size: 12px;
    line-height: 19px;
    margin: 32px 0 0 32px;
    color: $gray-1;
}

.TittleAuth {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.82px;
    color: $black-main;
    margin-top: 28px;
}

.Label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.41px;
    color: $black-main;
}

.Input {
    width: 100%;
    height: 50px;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: $dark-grey;
    transition: $transition;
    border: 1px solid $gray-6;
    box-sizing: border-box;
    border-radius: $radius-main;

    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    &:last-of-type {
        margin-bottom: 20px;
    }

    &::placeholder {
        color: #b3b8bd;
    }

    &:focus::placeholder {
        color: $gray-1;
    }
}

.FormError {
    .Input {
        border: 2px solid $coral;
        color: $coral;
    }
}

.ErrorMessage {
    color: $coral;
    padding: 15px;
    margin-top: -20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.FormAuth {
    background: #FFFFFF;
    width: 580px;
    height: 484px;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
    border-radius: $radius-main;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Button {
    align-self: flex-end;
    margin-bottom: 74px;
    border: none;
    transition: $transition;
    cursor: pointer;
    width: 100%;
    height: 56px;
    left: 550px;
    top: 437px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    color: $white-main;
    background: $purple-dark;
    box-shadow: 0px 4px 10px rgba(95, 144, 237, 0.15);
    border-radius: $radius-main;

    &:hover {
        background: $purple;
    }

    &:active {
        background-color: #15a054;
    }

    &[disabled] {
        color: #000000;
        background-color: #eaf2ff;
        cursor: auto;
    }
}

.VideoBGContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    opacity: 1;

    transition: opacity 0.5s;

    &.ToForeground {
        z-index: 1000;
    }

    &.Hidden {
        // display: none;
        opacity: 0;
    }
}

.VideoBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-aspect-ratio: "16/9") {
    .VideoBG {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: "16/9") {
    .VideoBG {
        width: 100%;
        height: 100%;
        // width: 300%;
        // left: -100%;
    }
}