@import '../../../assets/scss/variables.scss';

.Badge {
    width: 100%;
    border-radius: 10px;
    color: black;
}

.BadgeContent {
    width: 100%;
    padding: 0;
    margin: 0;
}