@import "../../assets/scss/variables.scss";

@mixin Button {
  border: none;
  border-radius: 10px;
  color: white;
  height: 30px;
  min-width: 70px;

  opacity: 1;
  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 0.7;
  }
}

.ButtonYellow {
  @include Button;
  color: black;
  background-color: #f4db50;
  &:hover {
    opacity: 1;
    background-color: #ffed84;
  }
  &:active {
    opacity: 1;
    background-color: #dbc442;
  }
}

.ButtonWhite {
  @include Button;
  color: black;
  background-color: white;
  border: 1px solid lightgray;
  &:hover {
    opacity: 1;
    background-color: #f6f6f6;
  }
  &:active {
    opacity: 1;
    background-color: #dddddd;
  }
}

.ButtonBlue {
  @include Button;
  background-color: #0C5DFF;
  border: 1px solid #0C5DFF;
  color: white;
}

.ButtonGreen {
  @include Button;
  background-color: #1bc167;
  border: 1px solid #1bc167;
}

.ButtonRed {
  @include Button;
  background-color: #ed4c4c;
  border: 1px solid #ed4c4c;
}
