@import "../../../assets/scss/buttons.scss";

.Table {
    z-index: 0;
    width: 100%;
    border-collapse: separate;

    th,
    td {
        padding: 12px 0 12px;
        height: 56px;
        text-align: start;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        color: $gray-5;
    }

    tbody tr:nth-child(even) td,
    thead th {
        background-color: $white-main;

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            width: 180px;
        }

        &:nth-child(2) {
            width: 140px;
        }

        &:nth-child(3) {
            width: 200px;
        }

        &:nth-child(4) {
            width: 128px;
        }

        &:nth-child(5) {
            width: 110px;
        }

        &:nth-child(6) {
            width: 120px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}