@import "../../assets/scss/variables.scss";

body {
    background: #1D0E56 !important;
    overflow-y: auto;
}

.Wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    flex-grow: 1;
    background-color: $gray-6;
    min-width: 1170px;
    overflow-x: auto;
}

.container {
    overflow: hidden !important;
}

.Main {
    width: 1180px;
    min-height: 820px;
    margin-left: 248px;
    padding-bottom: 20px;
}

.PreloadBackground {
    background-color: $cold-blue;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.MainError.MainError {
    height: $full-height;
    grid-column: span 3;
    position: relative;
}

.LazyMainError {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ff8888;
    background: $cold-blue;

    >* {
        margin-bottom: 20px;
    }
}

@media (max-width: $big-desktop) {
    .Main {
        width: 915px;
    }
}

@media (max-width: $desktop) {
    .Wrap {
        min-width: 568px;
        padding-bottom: 20px;
    }

    .MenuOpen {
        position: absolute;
        height: 100px;
        background: red;
    }

    .Main {
        margin: 0 auto;
        width: 550px;
    }
}

.officeBox {

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(128, 128, 128, 0.705);
        border-radius: 5px;
    }

    &::-webkit-scrollbar {
        height: 5px;
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
        display: none;
        width: 0;
        background-color: transparent;
    }
}

@media (max-width: $full-desktop) {
    .Wrap {}

    .MenuOpen {
        padding-top: 116px;
        z-index: 2000;
    }

    .Main {
        margin: 0 auto;
    }
}

iframe {
    width: 100% !important;
    height: 100%;
}