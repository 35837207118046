@import "../../../assets/scss/variables.scss";

$border-width: 1px;

/** pseudo element or empty */
@mixin ButtonColoring($where) {
  &#{$where} {
    border-radius: 10px;
    background-color: var(--background-color);
    border: $border-width solid var(--background-color);
    transition: filter 0.1s;
  }
  &:hover:not([disabled])#{$where} {
    // opacity: 0.85;
    filter: brightness(1.1);
  }
  &:active:not([disabled])#{$where} {
    // opacity: 0.7;
    filter: brightness(0.9);
  }
  &:focus:not([disabled])#{$where} {
    border: 2px solid rgb(255, 170, 100);
  }
}

.Button {
  --foreground-color: white;
  border: none;
  color: var(--foreground-color);
  background-color: transparent;
  height: 30px;
  min-width: 70px;
  position: relative;
  display: block;
  &:focus {
    outline: none;
  }

  &[disabled] {
    --foreground-color: #{$dark-grey};
    --background-color: #{$light-grey};
    cursor: not-allowed;
  }

  //&::after {
  //  content: "";
  //  position: absolute;
  //  display: block;
  //  z-index: -1;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  width: 100%;
  //  height: 100%;
  //  box-sizing: border-box;
  //}

  //@include ButtonColoring("::after");
}

.ButtonYellow {
  --foreground-color: black;
  --background-color: #f4db50;
  // &:hover {
  //   opacity: 1;
  //   background-color: #ffed84;
  // }
  // &:active {
  //   opacity: 1;
  //   background-color: #dbc442;
  // }
}

.ButtonWhite {
  --foreground-color: black;
  --background-color: white;
  &:not([disabled])::after {
    border: $border-width solid lightgray;
  }
  &:hover:not([disabled])::after {
    filter: brightness(0.95);
  }
  &:active:not([disabled])::after {
    filter: brightness(0.87);
  }
  // &:hover {
  //   opacity: 1;
  //   background-color: #f6f6f6;
  // }
  // &:active {
  //   opacity: 1;
  //   background-color: #dddddd;
  // }
}

.ButtonBlue {
  background-color: #0C5DFF;
  border-radius: 10px;

}

.ButtonGreen {
  background-color: #1bc167;
  border-radius: 10px;

}

.ButtonRed {
  background-color: #ed4c4c;
  border-radius: 10px;
}

.ButtonClear {
  --background-color: transparent;
  &[disabled] {
    --background-color: transparent;
  }
  --foreground-color: rgba(100, 100, 100, 0.6);
  &[data-use-fill] path {
    fill: var(--foreground-color);
  }
  &:hover:not([disabled])::after {
    backdrop-filter: brightness(0.95);
    filter: none;
  }
  &:active:not([disabled])::after {
    backdrop-filter: brightness(0.87);
    filter: none;
  }
  width: fit-content;
  min-width: unset;
  padding: 5px;

  svg {
    width: 100%;
    height: 100%;
  }
}
