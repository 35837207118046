@import "../../../assets/scss/variables.scss";

.Input {
    border-radius: 10px;
    padding: 9px 27px;
    border: 1px solid lightgrey;
    // margin-left: 45px;
    width: 280px;
    flex-shrink: 0;

    transition: border-color 0.35s, box-shadow 0.35s;

    &:focus {
        border: 1px solid #508ff471;
        box-shadow: 0 0 5px 2px #508ff454;
        outline: none;
    }
}

.newInput {
    width: 132px;
    height: 48px;
    border: 1px solid $gray-6;
    border-radius: $radius-main;
    padding: 16px 16px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: white;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $gray-5;

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #5F90ED;
    }
}

.inputBlock {
    width: 100%;
    height: 18px;
    border: none;
    background: inherit;
    padding: 0;
    text-align: center;
}

.inputBlockWithClose {
    padding: 0;
    width: 94%;
    height: 18px;
    border: none;
    background: inherit;
}

.iconClose {
    position: absolute;
    vertical-align: middle;
    right: 15px;
    top: 11px;
}

.fullWidth {
    width: 100% !important;
}