@import "../../../assets/scss/variables.scss";

.Price {
    font-size: 14px;
    background-color: $purple-dark;
    padding: 8px 10px;
    color: white;
    border-radius: 10px;
    width: 164px;
    height: 40px;
    white-space: nowrap;
}

.Balance {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $gray-3;
}

.count {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: $white-main;
}

.text {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: $white-main;
}