@font-face {
    font-family: "Museo Sans";
    src: url("../fonts/MuseoSansCyrl-300.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Museo Sans";
    src: url("../fonts/MuseoSansCyrl-500.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Museo Sans";
    src: url("../fonts/MuseoSansCyrl-700.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}